import React from "react";

const Grids = () => {
  return (
    <div className="grids__container">
      {Array.from({ length: 18 }).map((_, rowIndex) => (
        <div className="row" key={rowIndex}>
          {Array.from({ length: 30 }).map((_, colIndex) => (
            <div className="col" key={colIndex}></div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Grids;
