import React from "react";
import "./style/App.scss";
import { Home, About, DumpYard, Projects, NotFound } from "./pages";
import { Route, Routes } from "react-router-dom";
import CaseStudy from "./pages/CaseStudy";
import AnimatedCursor from "react-animated-cursor";
import ProjectsDetail from "./pages/ProjectsDetail";

const App = () => {
  return (
    <>
      <div className="App">
        <AnimatedCursor
          outerStyle={{
            mixBlendMode: "difference",
            backgroundColor: "#e85a58",
            zIndex: 10000,
          }}
          innerStyle={{
            mixBlendMode: "difference",
            backgroundColor: "#f6e7ca",
            zIndex: 10000,
          }}
        />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/dumpyard" element={<DumpYard />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/selected-work/:slug" element={<CaseStudy />} />
        <Route path="/projects/:slug" element={<ProjectsDetail />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
