import React, { useState } from "react";
import { LottieButton } from "../components/LottieButton";
import { ReactComponent as ArrowDown } from "../assets/icons/ArrowDown.svg";
import meow from "../assets/audio/meow.mp3";
import BadCat from "../assets/lotties/BadCat";
import { scrollToSection } from "../utils/scrollToSection";
import HeaderGallery from "./HeaderGallery";

const HeaderFooter = () => {
  const [audio] = useState(new Audio(meow));
  const handleMouseEnter = () => {
    audio.play();
  };

  return (
    <div className="header__footer">
      <div className="footer__left">
        <div className="left__tag">
          "a day in a life of a <br /> ____ kinda stuff
        </div>
        <HeaderGallery />
      </div>
      <div className="footer__right">
        <div className="right__tag" onMouseEnter={handleMouseEnter}>
          "life is beowtiful <br /> isnt it_
        </div>
        <div className="button__container">
          <LottieButton
            Icon={<ArrowDown />}
            lottie={BadCat}
            onclick={() => scrollToSection("selected-work")}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderFooter;
