import React from "react";
import { ReactComponent as InstagramIcon } from "../assets/icons/instagram.svg";
import { ReactComponent as MediumIcon } from "../assets/icons/medium.svg";
import { ReactComponent as LinkedinIcon } from "../assets/icons/linkedin.svg";
import { ReactComponent as YoutuebIcon } from "../assets/icons/youtube.svg";
import { ReactComponent as GithubIcon } from "../assets/icons/github.svg";
import { ReactComponent as GumroadIcon } from "../assets/icons/gumroad.svg";
import { ReactComponent as ArrowUp } from "../assets/icons/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "../assets/icons/ArrowDown.svg";
import CatWork from "../assets/lotties/CatWork";
import Lazy from "../assets/lotties/lazy";
import Grids from "../components/Grid";
import { LottieButton } from "../components/LottieButton";
import { Lines } from "./Lines";
import { scrollToSection } from "../utils/scrollToSection";

const Social = () => {
  return (
    <section id="social" className="section contact-section padding">
      <div className="contact-container">
        <div className="contact-title">
          <span className="title">chit chat chai_</span>
          <span className="tag">with your UXGUY_</span>
        </div>
        <div className="social-container">
          <ul>
            <li className="social-items">
              <a
                href="https://www.instagram.com/uxkeo_"
                className="social-links"
                target="_blank"
                rel="noreferrer noopener"
              >
                <InstagramIcon />
              </a>
            </li>
            <li className="social-items">
              <a
                href="https://www.medium.com/@uxkeo"
                className="social-links"
                target="_blank"
                rel="noreferrer noopener"
              >
                <MediumIcon />{" "}
              </a>
            </li>
            <li className="social-items">
              <a
                href="https://www.linkedin.com/in/vinay-choudhari/"
                className="social-links"
                target="_blank"
                rel="noreferrer noopener"
              >
                <LinkedinIcon />
              </a>
            </li>
          </ul>
          <ul>
            <li className="social-items">
              <a
                href="https://www.youtube.com/@uxkeo"
                className="social-links"
                target="_blank"
                rel="noreferrer noopener"
              >
                <YoutuebIcon />
              </a>
            </li>
            <li className="social-items">
              <a
                href="https://github.com/keo1925"
                className="social-links"
                target="_blank"
                rel="noreferrer noopener"
              >
                <GithubIcon />
              </a>
            </li>
            <li className="social-items">
              <a
                href="https://uxkeo.gumroad.com/"
                className="social-links"
                target="_blank"
                rel="noreferrer noopener"
              >
                <GumroadIcon />
              </a>
            </li>
          </ul>
        </div>
        <div className="contact-arrows">
          <LottieButton Icon={<ArrowUp />} lottie={CatWork} 
          onclick={()=>scrollToSection("service")}
          />
          <LottieButton Icon={<ArrowDown />} lottie={Lazy} 
          onclick={()=>scrollToSection("footer")}
          />
        </div>
      </div>
      <Lines HLine={1} VLine={1} />
      <Grids />
    </section>
  );
};

export default Social;
