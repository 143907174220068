import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import projects from "../data/projectsData";
import { Lines } from "../components/Lines";
import Grids from "../components/Grid";
import { LottieButton } from "../components/LottieButton";
import { ReactComponent as ArrowLeft } from "../assets/icons/ArrowLeft.svg";
import lazy from "../assets/lotties/lazy";
import Footer from "../components/Footer";
import Header from "../components/Header";

const ProjectsDetail = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const project = projects.find((p) => p.slug === slug);

  if (!project) {
    return (
      <div className="casestudy__notfound section">
        <p>
          error:404 <br /> _project not found
        </p>
        <Link to="/">back to home_</Link>
        <Lines HLine={1} VLine={1} />
        <Grids />
      </div>
    );
  }

  const {
    title,
    subtitle,
    overview,
    img1,
    img2,
    challenge,
    img3,
    solution,
    img4,
    result,
    img5,
    img6,
    img7,
  } = project;

  return (
    <>
    <Header/>
      <div id="home" className="detailed-project grid__component">
        <div className="back__button">
          <LottieButton
            Icon={<ArrowLeft />}
            lottie={lazy}
            onclick={() => navigate(`/projects`)}
          />
        </div>
        <div className="project-header">
          <div className="project-info">
            <h1>{title}</h1>
            <p>{subtitle}</p>
            <div className="project-meta">
              <div>
                <strong>client_</strong> {overview.client}
              </div>
              <div>
                <strong>industry_</strong> {overview.industry}
              </div>
              <div>
                <strong>duration_</strong> {overview.duration}
              </div>
            </div>
          </div>
        </div>

        <section className=" overview">
          <h2>overview_</h2>
          <p>{overview.paragraph}</p>
          <div className="overview-details">
            <div>
              <strong>services_</strong> {overview.services.join(", ")}
            </div>
          </div>
          <img src={img1} alt={`${title} Overview`} />
        </section>

        <section className=" challenge">
          <h2>the challenge_</h2>
          <p>{challenge}</p>
          <img src={img2} alt={`${title} Challenge`} />
        </section>

        <section className=" solution">
          <h2>the solution_</h2>
          <p>{solution}</p>
          <img src={img3} alt={`${title} Solution`} />
        </section>

        <section className="result">
          <h2>the result_</h2>
          <p>{result}</p>
          <img src={img4} alt={`${title} Result`} />
        </section>

        <section className="additional-images">
          <div className="images-grid">
            <img src={img5} alt={`${title} `} />
            <img src={img6} alt={`${title} `} />
            <img src={img7} alt={`${title} `} />
            <img src={img5} alt={`${title} `} />
            <img src={img6} alt={`${title} `} />
            <img src={img7} alt={`${title} `} />
          </div>
        </section>
      </div>
      <Footer/>
    </>
  );
};

export default ProjectsDetail;
