import React from "react";
import featuredWork from "../data/featuredWork";
import { ReactComponent as ButtonArrow } from "../assets/icons/ButtonArrow.svg";
import { useNavigate } from "react-router-dom";

const FeaturedGallery = ({ currentSlide }) => {
  const navigate = useNavigate();
  const currentProject = featuredWork[currentSlide];

  const handleCaseStudy = () => {
    navigate(`/selected-work/${currentProject.slug}`);
  };

  const openLink = () => {
    window.open(currentProject.mediumLink, "_blank");
  };
  return (
    <div className="projects__container">
      <div className="project__info">
        <h2 className="project__title">{currentProject.title}</h2>
        <p className="project__description">
          {currentProject.problemStatement}
        </p>
        <div className="tags__container">
          <span>tags:</span>
          <ul>
            {currentProject.tags.map((tag, index) => (
              <li key={index}>{tag}</li>
            ))}
          </ul>
        </div>
        <div className="projects__button">
          <button className="casestudy__button" onClick={openLink}>
            casestudy_
            <ButtonArrow />
          </button>
          <button className="prototype__button" onClick={handleCaseStudy}>
            prototype_
            <ButtonArrow />
          </button>
        </div>
      </div>
      <div className="projects__image">
        <img src={currentProject.image} alt={currentProject.title} />
      </div>
    </div>
  );
};

export default FeaturedGallery;
