import React, { useEffect, useRef } from "react";
import { LottieButton } from "../components/LottieButton";
import { ReactComponent as ArrowUp } from "../assets/icons/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "../assets/icons/ArrowDown.svg";
import CatLook from "../assets/lotties/CatLook";
import LazyCat from "../assets/lotties/LazyCat";
import Grids from "../components/Grid";
import Build from "../assets/images/Build.jpg";
import Work from "../assets/images/work.jpg";
import Dream from "../assets/images/dream.jpg";
import Create from "../assets/images/create.jpg";
import { scrollToSection } from "../utils/scrollToSection";

const Service = () => {
  const serviceSectionRef = useRef(null);
  const tagsContainerRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const percent = e.clientX / window.innerWidth;
      tagsContainerRef.current.animate(
        {
          transform: `translateX(${
            percent * tagsContainerRef.current.offsetWidth * -1
          }px)`,
        },
        {
          fill: "forwards",
          duration: 4000,
        }
      );
    };

    const handleMouseEnter = () => {
      window.addEventListener("mousemove", handleMouseMove);
    };

    const handleMouseLeave = () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };

    const serviceSection = serviceSectionRef.current;
    serviceSection.addEventListener("mouseenter", handleMouseEnter);
    serviceSection.addEventListener("mouseleave", handleMouseLeave);
  });

  return (
    <div
      id="service"
      ref={serviceSectionRef}
      className="section service-section"
    >
      <div className="service-container">
        <div className="title-container">
          <span className="service-title">#BE OF SERVICE</span>
          <div className="tags-container" ref={tagsContainerRef}>
            <div className="service-tags">
              <span>Build_</span>
              <div className="img-wrapper">
                <img src={Build} alt="uxkeo" />
              </div>
            </div>
            <div className="service-tags">
              <span>Work_</span>
              <div className="img-wrapper">
                <img src={Work} alt="uxkeo" />
              </div>
            </div>
            <div className="service-tags">
              <span>Dream_</span>
              <div className="img-wrapper">
                <img src={Dream} alt="uxkeo" />
              </div>
            </div>
            <div className="service-tags">
              <span>Create_</span>
              <div className="img-wrapper">
                <img src={Create} alt="uxkeo" />
              </div>
            </div>
            <div className="service-tags">
              <span>Build_</span>
              <div className="img-wrapper">
                <img src={Build} alt="uxkeo" />
              </div>
            </div>
            <div className="service-tags">
              <span>Work_</span>
              <div className="img-wrapper">
                <img src={Work} alt="uxkeo" />
              </div>
            </div>
            <div className="service-tags">
              <span>Dream_</span>
              <div className="img-wrapper">
                <img src={Dream} alt="uxkeo" />
              </div>
            </div>
            <div className="service-tags">
              <span>Create_</span>
              <div className="img-wrapper">
                <img src={Create} alt="uxkeo" />
              </div>
            </div>
          </div>
        </div>
        <div className="service-arrows">
          <LottieButton
            Icon={<ArrowUp />}
            lottie={CatLook}
            onclick={() => scrollToSection("selected-work")}
          />
          <LottieButton
            Icon={<ArrowDown />}
            lottie={LazyCat}
            onclick={() => scrollToSection("social")}
          />
        </div>
      </div>
      <Grids />
    </div>
  );
};

export default Service;
