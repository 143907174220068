import React, { useEffect } from "react";
import Header from "../components/Header";
import Grids from "../components/Grid";
import Footer from "../components/Footer";
import Brain from "../assets/gifs/brain.gif";
import profileImage from "../assets/images/aboutKEO.jpg";
import gsap from "gsap";
import { LottieButton } from "../components/LottieButton";
import { scrollToSection } from "../utils/scrollToSection";
import { ReactComponent as ArrowDown } from "../assets/icons/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "../assets/icons/ArrowUp.svg";
import HandShake from "../assets/handshake.svg";
import lazy from "../assets/lotties/lazy";
import LazyCat from "../assets/lotties/LazyCat";
import CatUp from "../assets/lotties/CatUp";
import BadCat from "../assets/lotties/BadCat";
import CatLook from "../assets/lotties/CatLook";
import { Lines } from "../components/Lines";
import { TextPlugin } from "gsap/TextPlugin";
import { Helmet } from "react-helmet";

gsap.registerPlugin(TextPlugin);

const About = () => {
  useEffect(() => {
    let tl = gsap.timeline({ repeat: -1 });

    const words = ["Designeer_", "Developeer_", "Marwaadi_"];

    words.forEach((word) => {
      let typewriterText = gsap.timeline({
        repeat: 1,
        yoyo: true,
      });

      typewriterText.to(".typewriter-text", {
        duration: 1.4,
        text: word,
      });

      tl.add(typewriterText);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>about_</title>
        <meta
          name="description"
          content="Vinay is a self-taught designer and developer with experience in UX design, known for working in early stage startups. Over 2 years, he's worked on many projects for early startups, clients and agencies."
        />
        <link rel="canonical" href="https://uxkeo.com/about" />
        {/* Open Graph Tags */}
        <meta property="og:title" content="About | UXKEO_ Vinay Choudhari" />
        <meta
          property="og:description"
          content="Discover the journey, experiences, and passion behind Vinay`s work as a UX designer."
        />
        <meta property="og:url" content="https://uxkeo.com/about" />
        <meta property="og:type" content="website" />
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About | UXKEO_ Vinay Choudhari" />
        <meta
          name="twitter:description"
          content="Get to know Vinay Choudhari, a creative mind and UX designer crafting unique digital experiences."
        />
      </Helmet>
      <Header />
      <div id="home" className="about__section">
        <div className="about__container">
          <div className="button">
            <LottieButton
              Icon={<ArrowDown />}
              lottie={lazy}
              onclick={() => scrollToSection("image")}
            />
          </div>
          <div className="left">
            <div className="top">
              <div className="gif">
                <img src={Brain} alt="Repairing Brain" />
              </div>
              <div className="quote">
                <span>"Just some repairs and we are good to go_</span>
              </div>
            </div>
            <div className="bottom">
              <span>"bainstorming_</span>
            </div>
          </div>
          <div className="right">
            <span className="title">the page is under "ideation_</span>
            <span className="tag">
              some random paragraph Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Tempora placeat assumenda quis!
            </span>
            <span className="quote">
              Keep "scrolling_ maybe you will find something_ 👀
            </span>
          </div>
        </div>
        <Grids />
      </div>
      <div className="about__image" id="image">
        <div className="about__info">
          <div className="left-container">
            <span className="about__tag">
              .<span className="typewriter-text"></span>
            </span>
            <div className="about__buttons">
              <LottieButton
                Icon={<ArrowUp />}
                lottie={CatUp}
                onclick={() => scrollToSection("home")}
              />
              <LottieButton
                Icon={<ArrowDown />}
                lottie={BadCat}
                onclick={() => scrollToSection("work")}
              />
            </div>
          </div>
          <div className="right-container">
            <div className="top">
              <h1 className="title">about_</h1>
              <p className="description">
                more than a ux designer, I'm a storyteller at heart, weaving
                narratives through my designs. <br /> Started dismantling things
                since I was a child just to know how certain things worked.{" "}
                <br />
                in my free time i keep exploring new wonders of technology and
                about life_ <span>cause life`s beautiful</span> <br /> In
                addition, I read a lot of books, you can always find me in
                libraries and book cafes.
              </p>
            </div>
            <div className="bottom">
              <div className="experience">
                <h2>experience_</h2>
                <ul>
                  <li>24/25_ new journey_ @contentCreation</li>
                  <li>23/24_ freelance_ @VarientSpark @BerrDecor @yepehen</li>
                  <li>22/23_ contract_ @EVtrical @SaharaAI</li>
                  <li>21/22_ internship_ @GDIOT Innovations</li>
                </ul>
              </div>
              <div className="profile-image">
                <img src={profileImage} alt="portrait of uxkeo" />
              </div>
            </div>
          </div>
        </div>
        <Grids />
      </div>
      <div className="work__container" id="work">
        <div className="handshake">
          <img src={HandShake} alt="Handshake svg" />
        </div>
        <div className="work">
          <div className="work-info">
            <span>
              a great project starts with a <br /> <b>handshake_</b>
            </span>
            &nbsp; &nbsp; &nbsp;
            <span className="idea">have a great idea count me in_</span>
          </div>
          <div className="handshake__button">
            <a
              href="https://api.whatsapp.com/send?phone=917499188832&text=Hello! KEO_ we are building something great @"
              target="_blank"
              rel="noreferrer noopener"
            >
              "DM_
            </a>
          </div>
          <div className="work__buttons">
            <LottieButton
              Icon={<ArrowUp />}
              lottie={CatLook}
              onclick={() => scrollToSection("image")}
            />
            <LottieButton
              Icon={<ArrowDown />}
              lottie={lazy}
              onclick={() => scrollToSection("empty")}
            />
          </div>
        </div>
        <Grids />
      </div>
      <div className="empty__container" id="empty">
        <div className="empty">
          <p>woooow_</p>
          <span>
            designers_ would_ stare_ at_ an_ empty_ screen_ pretending_ to_
            work_ but_ thats_ when_ they_ invented_ the_ concept_ of_ ideation_
          </span>
          <span className="tag">just stare at the screen_ 🫣</span>
        </div>
        <div className="empty__buttons">
          <LottieButton
            Icon={<ArrowUp />}
            lottie={lazy}
            onclick={() => scrollToSection("work")}
          />
          <LottieButton
            Icon={<ArrowDown />}
            lottie={LazyCat}
            onclick={() => scrollToSection("footer")}
          />
        </div>
        <Lines HLine={1} VLine={1} />
        <Grids />
      </div>
      <Footer />
    </>
  );
};

export default About;
