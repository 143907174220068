import React from "react";
import { importImages } from "../utils/importImages";
import Marquee from "react-fast-marquee";

const images = importImages(
  require.context(
    "../assets/images/projects/workprocess",
    false,
    /\.(png|jpe?g|JPG|jpeg)$/
  )
);

const ProjectsGallery = () => {
  return (
    <Marquee className="gallery__slider" pauseOnHover speed={100}>
      {Object.values(images).map((src, index) => (
        <img src={src} alt="" />
      ))}
    </Marquee>
  );
};

export default ProjectsGallery;