import React, { useState } from "react";
import { LottieButton } from "../components/LottieButton";
import { ReactComponent as NavMenu } from "../assets/icons/NavMenu.svg";
import HeyCat from "../assets/lotties/HeyCat";
import { Link } from "react-router-dom";
import OverlayNav from "./OverlayNav";
import Marquee from "react-fast-marquee";

const Header = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const handleOpen = () => {
    setShowOverlay(true);
  };

  const handleRequestClose = () => {
    setShowOverlay(false);
  };
  return (
    <header>
      <Marquee className="message">
        {" "}
        i_ am_ still_ building_ this_ site_ as_ i_ learn_ to_ code_ i_ will_
        keep_ updating_ things_ and_ keep_ this_ site_ as_ my_ canvas_ to_
        experiment_ things_{" "}
        <span>....ありがとうございます Thankyou_ visit_ again_....</span>
      </Marquee>
      <div className="nav">
        <div className="logo">
          <Link to="/">UXKEO_</Link>
        </div>
        <div className="navButton">
          <LottieButton
            Icon={<NavMenu />}
            lottie={HeyCat}
            onclick={handleOpen}
          />
        </div>
      </div>
      {showOverlay && (
        <OverlayNav isOpen={showOverlay} onRequestClose={handleRequestClose} />
      )}
    </header>
  );
};

export default Header;
