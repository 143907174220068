import { useState, useRef } from "react";
import resume from "../assets/pdf/resume.pdf";

const RecruiterButton = () => {
  const [open, setOpen] = useState(false);
  const videoRef = useRef(null);

  return (
    <div>
      {/* Recruiter Button */}
      <button onClick={() => setOpen(true)} className="recruiter-button">
        for recruiters_
      </button>

      {/* Modal */}
      {open && (
        <div
          className="modal-overlay"
          onClick={() => setOpen(false)}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title"
        >
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h2 id="modal-title">just 45seconds_</h2>
              <button onClick={() => setOpen(false)} className="close-button" aria-label="Close modal">
                &times;
              </button>
            </div>
            <div className="video-container">
              <video ref={videoRef} controls>
                <source
                  src="https://videos.pexels.com/video-files/7426347/7426347-uhd_2560_1440_25fps.mp4"
                  type="video/mp4"
                />
                <source src="/path-to-your-video.webm" type="video/webm" />
                <source src="/path-to-your-video.ogv" type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="download-container">
              <a
                href={resume}
                download
                className="download-button"
                aria-label="Download resume"
              >
                download resume_
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecruiterButton;
