import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { LottieButton } from "./LottieButton";
import { ReactComponent as NavIcon } from "../assets/icons/NavArrowUp.svg";
import { ReactComponent as InputArrow } from "../assets/icons/InputArrow.svg";
import CatUp from "../assets/lotties/CatUp";
import Beowtiful from "../assets/lotties/Beowtiful";
import Grids from "./Grid";
import emailjs from "@emailjs/browser";

const OverlayNav = ({ isOpen, onRequestClose }) => {
  const [animationState, setAnimationState] = useState("");
  const [showFields, setShowFields] = useState(false);

  const form = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setAnimationState("open");
    } else {
      setAnimationState("close");
    }
  }, [isOpen]);

  const handleAnimationEnd = () => {
    if (animationState === "close") {
      onRequestClose();
    }
  };

  const handleClose = () => {
    setAnimationState("close");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        {
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
        }
      )
      .then(
        (result) => {
          alert("your thoughts were sent successfully_ 🫡");
          console.log("Email sent:", result.text);
          form.current.reset();
          setShowFields(false);
        },
        (error) => {
          alert("oops_ something went wrong. please try again_ 🙂‍↔️");
          console.error("Email send error:", error);
        }
      );
  };

  const handleFocusCapture = () => {
    setShowFields(true);
  };
  const handleBlurCapture = (e) => {
    if (!form.current.contains(e.relatedTarget)) {
      setShowFields(false);
    }
  };

  return (
    <div
      className={`overlay ${animationState}`}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className="nav__button">
        <LottieButton Icon={<NavIcon />} lottie={CatUp} onclick={handleClose} />
      </div>

      <nav className="overlay__menu">
        <ul>
          <li>
            <span>00</span>
            <Link to="/" onClick={handleClose}>
              home_
            </Link>
          </li>
          <li>
            <span>01</span>
            <Link to="/about" onClick={handleClose}>
              about_
            </Link>
          </li>
          <li>
            <span>02</span>
            <Link to="/dumpyard" onClick={handleClose}>
              dumpyard_
            </Link>
          </li>
          <li>
            <span>03</span>
            <Link to="/projects" onClick={handleClose}>
              projects_
            </Link>
          </li>
        </ul>

        <form
          ref={form}
          onSubmit={handleSubmit}
          onFocusCapture={handleFocusCapture}
          onBlurCapture={handleBlurCapture}
          className="contact-form"
        >
          {showFields && (
            <>
              <div className="data__input">
                <input
                  type="text"
                  name="user_name"
                  placeholder="what should i call you_"
                  required
                />
              </div>
              <div className="data__input">
                <input
                  type="email"
                  name="user_email"
                  placeholder="email_"
                  required
                />
              </div>
            </>
          )}

          <div className="input">
            <input
              id="input-data"
              type="text"
              name="user_message"
              placeholder="Hmm... what are you thinking today_"
              required
            />
            <div className="input-arrow">
              <button type="submit">
                <LottieButton Icon={<InputArrow />} lottie={Beowtiful} />
              </button>
            </div>
          </div>
        </form>
      </nav>
      <Grids />
    </div>
  );
};

export default OverlayNav;
