import React from "react";
import Header from "../components/Header";
import Grids from "../components/Grid";
import Footer from "../components/Footer";
import { Lines } from "../components/Lines";
import { scrollToSection } from "../utils/scrollToSection";
import { LottieButton } from "../components/LottieButton";
import { ReactComponent as ArrowDown } from "../assets/icons/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "../assets/icons/ArrowUp.svg";
import CatUp from "../assets/lotties/CatUp";
import BadCat from "../assets/lotties/BadCat";
import CatLook from "../assets/lotties/CatLook";
const DumpYard = () => {
  return (
    <>
      <Header />
      <div id="home" className="dump__section section">
        <div className="dump__yard">
          <div className="dump__title">
            <h1 className="title">"Dumpyard_</h1>
            <span className="tagline">
              ____this page is way behind of its development time_
            </span>
          </div>
          <div className="button__container">
            <LottieButton
              Icon={<ArrowDown />}
              lottie={CatLook}
              onclick={() => scrollToSection("soon")}
            />
          </div>
        </div>
        <Lines HLine={1} VLine={1} />
        <Grids />
      </div>
      <div className="soon section" id="soon">
        <div className="soon__container">
          <span>
            {" "}
            <b> dedicating_ this_ page_ as_ my_ digital_ diary_ </b> Lorem ipsum
            dolor sit amet consectetur.{" "}
            <b> planning_ to_ make_ public_ soon_ </b> Lorem ipsum{" "}
            <b> Lots_ of_ coooooooool_ stuff_</b> dolor sit.{" "}
            <b> its all about my journey so far_ </b> Lorem, ipsum.{" "}
            <b>
              {" "}
              want_ to_ be_ a part_ of it_ stay_ connected_ on_{" "}
              <a href="https://www.instagram.com/uxkeo_">@instagram_</a>{" "}
            </b>
          </span>
          <div className="visibility">
            <div className="visibility__container">
              <p> &#123; visibility: hidden_ &#125; </p>
              <p> &#123; isAvailable(False) = ((when)) = &gt; soon_ &#125; </p>
            </div>
            <div className="soon__arrow">
              <LottieButton
                Icon={<ArrowUp />}
                lottie={CatUp}
                onclick={() => scrollToSection("home")}
              />
              <LottieButton
                Icon={<ArrowDown />}
                lottie={BadCat}
                onclick={() => scrollToSection("footer")}
              />
            </div>
          </div>
        </div>
        <Lines HLine={2} VLine={1} />
        <Grids />
      </div>
      <Footer />
    </>
  );
};

export default DumpYard;
