import React, { useRef, useState } from "react";

function CompareImages({
  beforeSrc,
  afterSrc,
  resetOnLeave = true, // if true, slider snaps back to 50% on mouse leave / touch end
}) {
  const containerRef = useRef(null);
  const [sliderPos, setSliderPos] = useState(50); // Start at 50%

  // ========== DESKTOP MOUSE EVENTS ==========
  const handleMouseMove = (e) => {
    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    updateSliderPos(x, rect.width);
  };

  const handleMouseLeave = () => {
    if (resetOnLeave) {
      setSliderPos(50);
    }
  };

  // ========== TOUCH EVENTS ==========
  const handleTouchMove = (e) => {
    const rect = containerRef.current.getBoundingClientRect();
    // For multi-touch, use the first touch
    const x = e.touches[0].clientX - rect.left;
    updateSliderPos(x, rect.width);
  };

  const handleTouchEnd = () => {
    if (resetOnLeave) {
      setSliderPos(50);
    }
  };

  // ========== UTILITY FUNCTION ==========
  const updateSliderPos = (x, containerWidth) => {
    let newPos = (x / containerWidth) * 100;
    if (newPos < 0) newPos = 0;
    if (newPos > 100) newPos = 100;
    setSliderPos(newPos);
  };

  return (
    <div
      className="compare-container"
      ref={containerRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <img
        src={afterSrc}
        alt="After"
        className="compare-image compare-image--after"
      />
      <div className="compare-image-wrapper" style={{ width: `${sliderPos}%` }}>
        <img
          src={beforeSrc}
          alt="Before"
          className="compare-image compare-image--before"
        />
      </div>
      <div className="compare-handle" style={{ left: `${sliderPos}%` }}>
        <div className="compare-handle-bar" />
      </div>
    </div>
  );
}

export default CompareImages;
