import React from "react";
import Header from "../components/Header";
import Grids from "../components/Grid";
import Footer from "../components/Footer";
import { Lines } from "../components/Lines";
import ProjectsGallery from "../components/ProjectsGallery";
import { LottieButton } from "../components/LottieButton";
import { scrollToSection } from "../utils/scrollToSection";
import { ReactComponent as ArrowDown } from "../assets/icons/ArrowDown.svg";
import BadCat from "../assets/lotties/BadCat";
import ProjectsContainer from "../components/ProjectsContainer";
import { Helmet } from "react-helmet";

const Projects = () => {
  return (
    <>
      <Helmet>
        <title>projects_</title>
        <meta
          name="description"
          content="Explore a collection of projects showcasing a journey from early UI designs to comprehensive case studies and hackathon entries."
        />
        <link rel="canonical" href="https://uxkeo.com/projects" />
      </Helmet>
      <Header />
      <div id="home" className="projects__section section">
        <div className="project__container">
          <div className="info">
            <h1 className="project__title">projects_</h1>
            <p className="projects__description">
              this page is a collection of all the designs i have ever created_
              from my 1st ever ui design_ ui challenges_ hackathons_ case
              studies_ and more_
            </p>
          </div>
          <ProjectsGallery />
          <div className="buttons__container">
            <LottieButton
              Icon={<ArrowDown />}
              lottie={BadCat}
              onclick={() => scrollToSection("projects")}
            />
          </div>
        </div>
        <Lines HLine={1} VLine={1} />
        <Grids />
      </div>
      <section className="projects grid__component">
        <ProjectsContainer />
        <Grids />
      </section>
      <Footer />
    </>
  );
};

export default Projects;
