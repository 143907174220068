// ProjectsContainer.jsx
import React from "react";
import { Link } from "react-router-dom";
import projects from "../data/projectsData";

const ProjectsContainer = () => {
  return (
    <div className="projects-container" id="projects">
      <div className="projects-grid">
        {projects.map((project) => (
          <Link
            key={project.id}
            to={`/projects/${project.slug}`}
            className="project-card"
          >
            <img
              src={project.coverImg}
              alt={project.title}
              className="project-image"
            />
            <div className="project-content">
              <h3>{project.title}</h3>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProjectsContainer;
