import React, { useRef } from "react";
import Lottie from "react-lottie";

export const LottieButton = ({
  Icon,
  lottie,
  height = 100,
  width = 100,
  onclick,
}) => {
  const lottieRef = useRef(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleMouseEnter = () => {
    if (lottieRef.current) {
      lottieRef.current.goToAndPlay(0, true);
    }
  };

  const handleMouseLeave = () => {
    if (lottieRef.current) {
      lottieRef.current.stop();
    }
  };

  return (
    <div
      className="lottie-container"
      onClick={onclick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="icon">{Icon}</div>
      <div className="lottie">
        <Lottie
          options={defaultOptions}
          height={height}
          width={width}
          ref={(animation) => {
            lottieRef.current = animation ? animation.anim : null;
          }}
        />
      </div>
    </div>
  );
};
