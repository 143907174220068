import astrotalk from "../assets/images/astrotalk-casestudy-image.jpg";
import gdiot from "../assets/images/gdiot-casestudy-image.jpg";
import gdiotRedesign from "../assets/images/gdiotRedesign.png";
import gdiotAfter from "../assets/images/gdiotAfter.png";
import gdiotBefore from "../assets/images/gdiotBefore.png";
import evtrical from "../assets/images/evtrical-casetudy-image.jpg";
import evtricalRedesign from "../assets/images/evtricalRedesign.png";
import astrotalkAfter from "../assets/images/astrotalkAfter.png";
import astrotalkBefore from "../assets/images/astrotalkBefore.png";
import astrotalkRedesign from "../assets/images/astrotalkRedesign.png";
import evtricalAfter from "../assets/images/evtricalAfter.png";
import evtricalBefore from "../assets/images/evtricalBefore.png";

const featuredWork = [
  {
    slug: "astrotalk-case-study",
    title: "astrotalk_",
    problemStatement:
      "_redesigning astrotalk apps listing page to gain user trust, resulting in increase of consultation bookings and reduce drop-offs.",
    tags: ["react_", "redux_", "redesign_"],
    image: astrotalk,
    // for detailed case study
    caseTitle: "_astrotalk",
    caseDescription:
      "helping the business build user trust and get more conversions_",
    afterImage: astrotalkAfter,
    beforeImage: astrotalkBefore,
    redesignImage: astrotalkRedesign,
    iframeTitle: "astrotalk redesign uxkeo",
    prototypeLink:
      "https://embed.figma.com/proto/udoAci8BUwWPZdVuqXyAmI/UXKEO-Website?page-id=170%3A36&node-id=242-22546&viewport=3007%2C-2538%2C0.47&scaling=scale-down&content-scaling=fixed&starting-point-node-id=242%3A22546&embed-host=share",
    mediumLink:
      "https://medium.com/@UXKEO/in-depth-ux-case-study-redesigning-astrotalk-s-listing-page-7a7bcc6e2df3",
  },
  {
    slug: "gdiot-case-study",
    title: "gdiot_",
    problemStatement:
      "_redesigning gdiots website to provide clear calls to action and highlight program benefits resulting in higher student engagement",
    tags: ["react", "redux", "node"],
    image: gdiot,
    afterImage: gdiotAfter,
    beforeImage: gdiotBefore,
    redesignImage: gdiotRedesign,
    caseTitle: "gdiot_",
    caseDescription:
      "from classrooms to career: empowering students with skills to conquer in real world_",
    iframeTitle: "astrotalk redesign uxkeo",
    prototypeLink:
      "https://embed.figma.com/proto/udoAci8BUwWPZdVuqXyAmI/UXKEO-Website?page-id=170%3A37&node-id=365-30980&viewport=607%2C-414%2C0.22&scaling=contain&content-scaling=fixed&starting-point-node-id=365%3A30980&embed-host=share",
    mediumLink:
      "https://www.behance.net/gallery/194595763/1st-UX-Internship-case-study",
  },
  {
    slug: "project-3",
    title: "evtrical_",
    problemStatement:
      "_a startup of young enthusiasts, with a vision to accelerate the adoption of eco-friendly technologies & solutions, to contribute towards a greener world.",
    tags: ["react", "redux", "node"],
    image: evtrical,
    // for detailed case study
    caseTitle: "_evtrical",
    caseDescription:
      "helping the young startup speak their voice through design_",
    afterImage: evtricalAfter,
    beforeImage: evtricalBefore,
    redesignImage: evtricalRedesign,
    iframeTitle: "astrotalk redesign uxkeo",
    prototypeLink:
      "https://embed.figma.com/proto/udoAci8BUwWPZdVuqXyAmI/UXKEO-Website?page-id=18%3A234&node-id=242-22546&viewport=2089%2C-1828%2C0.31&scaling=contain&content-scaling=fixed&embed-host=share",
    mediumLink:
      "https://medium.com/@UXKEO/",
  },
];

export default featuredWork;
