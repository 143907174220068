import marshallCoverImg from "../assets/images/projects/caseStudies/marshallsCoverImg.png";
import botanicaCoverImg from "../assets/images/projects/caseStudies/botanicaCoverImg.png";
import berrdecorCoverImg from "../assets/images/projects/caseStudies/berrdecorCoverImg.png";
import ifp14CoverImg from "../assets/images/projects/caseStudies/ifp14CoverImg.png";
import dailyuiCoverImg from "../assets/images/projects/caseStudies/dailyuiCoverImg.png";
import radCoverImg from "../assets/images/projects/caseStudies/radCoverImg.png";
import marshallsImg1 from "../assets/images/projects/caseStudies/marshallsImg1.png";
import marshallsImg2 from "../assets/images/projects/caseStudies/marshallsImg2.png";
import marshallsImg3 from "../assets/images/projects/caseStudies/marshallsImg3.png";

const projects = [
  {
    slug: "marshalls-book-cafe",
    title: "_marshalls book cafe",
    coverImg: marshallCoverImg,
    subtitle: "eat, read, succeed",
    img1: marshallsImg1,
    overview: {
      paragraph:
        "marshall`s book café a quite café that tends to the matters of mind, soul and heart_ it`s not just a café but a diverse community of people from book enthusiasts, poets, to screen play writer, story tellers, and more_",
      client: "project case study_",
      services: ["ui/ux_", "visual design_", "mobile first_"],
      industry: "cafe_",
      duration: "3 weeks_",
    },
    img2: marshallsImg2,
    challenge:
      "Marshall's Book Café aims to create a mobile app that seamlessly integrates reading, listening, community-building, and event registration. The challenge is to design an intuitive and engaging application that delivers a diverse range of content while fostering a sense of belonging within the café. Solving these challenge will enable Marshall's Book Café to provide a holistic platform for intellectual and creative enrichment.",
    img3: marshallsImg3,
    solution:
      "Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart. Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart. Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart.",
    img4: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    result:
      "A highly responsive and visually appealing app that enhanced the user experience for travelers.",
    img5: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    img6: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    img7: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
  },
  {
    slug: "ifp-season-14-50hrs-design-challenge",
    title: "_ifp #season14 50hrs design challenge_",
    coverImg: ifp14CoverImg,
    subtitle: "eat, read, succeed",
    img1: marshallsImg1,
    overview: {
      paragraph:
        "marshall`s book café a quite café that tends to the matters of mind, soul and heart_ it`s not just a café but a diverse community of people from book enthusiasts, poets, to screen play writer, story tellers, and more_",
      client: "project case study_",
      services: ["ui/ux_", "visual design_", "mobile first_"],
      industry: "cafe_",
      duration: "3 weeks_",
    },
    img2: marshallsImg2,
    challenge:
      "Marshall's Book Café aims to create a mobile app that seamlessly integrates reading, listening, community-building, and event registration. The challenge is to design an intuitive and engaging application that delivers a diverse range of content while fostering a sense of belonging within the café. Solving these challenge will enable Marshall's Book Café to provide a holistic platform for intellectual and creative enrichment.",
    img3: marshallsImg3,
    solution:
      "Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart. Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart. Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart.",
    img4: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    result:
      "A highly responsive and visually appealing app that enhanced the user experience for travelers.",
    img5: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    img6: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    img7: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
  },
  {
    slug: "berrdecor",
    title: "_berrdecor",
    coverImg: berrdecorCoverImg,
    subtitle: "eat, read, succeed",
    img1: marshallsImg1,
    overview: {
      paragraph:
        "marshall`s book café a quite café that tends to the matters of mind, soul and heart_ it`s not just a café but a diverse community of people from book enthusiasts, poets, to screen play writer, story tellers, and more_",
      client: "project case study_",
      services: ["ui/ux_", "visual design_", "mobile first_"],
      industry: "cafe_",
      duration: "3 weeks_",
    },
    img2: marshallsImg2,
    challenge:
      "Marshall's Book Café aims to create a mobile app that seamlessly integrates reading, listening, community-building, and event registration. The challenge is to design an intuitive and engaging application that delivers a diverse range of content while fostering a sense of belonging within the café. Solving these challenge will enable Marshall's Book Café to provide a holistic platform for intellectual and creative enrichment.",
    img3: marshallsImg3,
    solution:
      "Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart. Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart. Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart.",
    img4: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    result:
      "A highly responsive and visually appealing app that enhanced the user experience for travelers.",
    img5: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    img6: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    img7: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
  },
  {
    slug: "dailyui-100days-challenge",
    title: "_dailyui #100 days ui challenge_",
    coverImg: dailyuiCoverImg,
    subtitle: "eat, read, succeed",
    img1: marshallsImg1,
    overview: {
      paragraph:
        "marshall`s book café a quite café that tends to the matters of mind, soul and heart_ it`s not just a café but a diverse community of people from book enthusiasts, poets, to screen play writer, story tellers, and more_",
      client: "project case study_",
      services: ["ui/ux_", "visual design_", "mobile first_"],
      industry: "cafe_",
      duration: "3 weeks_",
    },
    img2: marshallsImg2,
    challenge:
      "Marshall's Book Café aims to create a mobile app that seamlessly integrates reading, listening, community-building, and event registration. The challenge is to design an intuitive and engaging application that delivers a diverse range of content while fostering a sense of belonging within the café. Solving these challenge will enable Marshall's Book Café to provide a holistic platform for intellectual and creative enrichment.",
    img3: marshallsImg3,
    solution:
      "Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart. Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart. Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart.",
    img4: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    result:
      "A highly responsive and visually appealing app that enhanced the user experience for travelers.",
    img5: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    img6: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    img7: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
  },
  {
    slug: "rad-nolan-70days-challenge",
    title: "_70rad challenge by Rad Nolan",
    coverImg: radCoverImg,
    subtitle: "eat, read, succeed",
    img1: marshallsImg1,
    overview: {
      paragraph:
        "marshall`s book café a quite café that tends to the matters of mind, soul and heart_ it`s not just a café but a diverse community of people from book enthusiasts, poets, to screen play writer, story tellers, and more_",
      client: "project case study_",
      services: ["ui/ux_", "visual design_", "mobile first_"],
      industry: "cafe_",
      duration: "3 weeks_",
    },
    img2: marshallsImg2,
    challenge:
      "Marshall's Book Café aims to create a mobile app that seamlessly integrates reading, listening, community-building, and event registration. The challenge is to design an intuitive and engaging application that delivers a diverse range of content while fostering a sense of belonging within the café. Solving these challenge will enable Marshall's Book Café to provide a holistic platform for intellectual and creative enrichment.",
    img3: marshallsImg3,
    solution:
      "Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart. Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart. Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart.",
    img4: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    result:
      "A highly responsive and visually appealing app that enhanced the user experience for travelers.",
    img5: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    img6: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    img7: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
  },
  {
    slug: "botanics",
    title: "_botanica",
    coverImg: botanicaCoverImg,
    subtitle: "eat, read, succeed",
    img1: marshallsImg1,
    overview: {
      paragraph:
        "marshall`s book café a quite café that tends to the matters of mind, soul and heart_ it`s not just a café but a diverse community of people from book enthusiasts, poets, to screen play writer, story tellers, and more_",
      client: "project case study_",
      services: ["ui/ux_", "visual design_", "mobile first_"],
      industry: "cafe_",
      duration: "3 weeks_",
    },
    img2: marshallsImg2,
    challenge:
      "Marshall's Book Café aims to create a mobile app that seamlessly integrates reading, listening, community-building, and event registration. The challenge is to design an intuitive and engaging application that delivers a diverse range of content while fostering a sense of belonging within the café. Solving these challenge will enable Marshall's Book Café to provide a holistic platform for intellectual and creative enrichment.",
    img3: marshallsImg3,
    solution:
      "Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart. Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart. Marshall`s Book Café a quite café that tends to the matters of mind, soul and heart.",
    img4: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    result:
      "A highly responsive and visually appealing app that enhanced the user experience for travelers.",
    img5: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    img6: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
    img7: "https://framerusercontent.com/images/20Q4dD9CnRYkEPZFRBWrWEWIAc.webp?scale-down-to=1024",
  },
];

export default projects;
