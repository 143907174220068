import React, { useState } from "react";
import { LottieButton } from "../components/LottieButton";
import { ReactComponent as ArrowLeft } from "../assets/icons/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/icons/ArrowRight.svg";
import CatLeft from "../assets/lotties/CatLeft";
import CatRight from "../assets/lotties/CatRight";
import Grids from "./Grid";
import FeaturedGallery from "./FeaturedGallery";
import featuredWork from "../data/featuredWork";
import { scrollToSection } from "../utils/scrollToSection";
import { Link } from "react-router-dom";

const FeaturedWork = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = featuredWork.length;

  const handlePrev = () => {
    if (currentSlide === 0) {
      setCurrentSlide(totalSlides - 1);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const handleNext = () => {
    if (currentSlide === totalSlides - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const baseTransition = {
    transition: "transform 0.4s ease",
  };

  const leftArrowStyle =
    currentSlide === 0
      ? { ...baseTransition, transform: "rotate(90deg)" }
      : { ...baseTransition, transform: "rotate(0deg)" };

  const rightArrowStyle =
    currentSlide === totalSlides - 1
      ? { ...baseTransition, transform: "rotate(90deg)" }
      : { ...baseTransition, transform: "rotate(0deg)" };

  const handleLeftClick = () => {
    if (currentSlide === 0) {
      scrollToSection("home");
    } else {
      handlePrev();
    }
  };

  const handleRightClick = () => {
    if (currentSlide === totalSlides - 1) {
      scrollToSection("service");
    } else {
      handleNext();
    }
  };

  const formattedSlide = String(currentSlide).padStart(2, "0");

  return (
    <div id="selected-work" className="featured__work section">
      <div className="work__container">
        <h2 className="work__title">
          selected work_
          <Link to="/projects">view all_</Link>
        </h2>
        <div className="featured__button">
          <div className="pagination">
            <span>{formattedSlide}</span>
          </div>
          <LottieButton
            Icon={<ArrowLeft style={leftArrowStyle} />}
            lottie={CatLeft}
            onclick={handleLeftClick}
          />

          <LottieButton
            Icon={<ArrowRight style={rightArrowStyle} />}
            lottie={CatRight}
            onclick={handleRightClick}
          />
        </div>
      </div>
      <FeaturedGallery currentSlide={currentSlide} />
      <Grids />
    </div>
  );
};

export default FeaturedWork;
