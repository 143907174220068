import React from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import featuredWork from "../data/featuredWork";
import CompareImages from "../components/CompareImages";
import Grids from "../components/Grid";
import { LottieButton } from "../components/LottieButton";
import { ReactComponent as ArrowLeft } from "../assets/icons/ArrowLeft.svg";
import lazy from "../assets/lotties/lazy";
import { Lines } from "../components/Lines";
import Footer from "../components/Footer";
import Header from "../components/Header";

const CaseStudy = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const project = featuredWork.find((project) => project.slug === slug);

  if (!project) {
    return (
      <div className="casestudy__notfound section">
        <p>
          error:404 <br /> _project not found
        </p>
        <Link to="/">back to home_</Link>
        <Lines HLine={1} VLine={1} />
        <Grids />
      </div>
    );
  }

  return (
    <>
    <Header/>
      <div id="home" className="casestudy__section">
        <div className="casestudy__info section">
          <div className="info">
            <h1 className="title">{project.caseTitle}</h1>
            <span className="description">{project.caseDescription}</span>
          </div>
          <div className="back__button">
            <LottieButton
              Icon={<ArrowLeft />}
              lottie={lazy}
              onclick={() => navigate(`/`)}
            />
          </div>
          <Lines HLine={1} VLine={1} />
          <Grids />
        </div>
        <div className="image__info">
          <CompareImages
            beforeSrc={project.beforeImage}
            afterSrc={project.afterImage}
          />
          <Grids />
        </div>
        <div className="prototype__frame">
          <iframe
            title={project.iframeTitle}
            src={project.prototypeLink}
            allowfullscreen
          ></iframe>
          <Grids />
        </div>
        <div className="screens__container">
          <img src={project.redesignImage} alt="" />
          <Grids />
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default CaseStudy;
