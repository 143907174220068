import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Grids from "../components/Grid";
import HeaderFooter from "../components/HeaderFooter";
import FeaturedWork from "../components/FeaturedWork";
import Service from "../components/Service";
import Footer from "../components/Footer";
import Socials from "../components/Socials";
import { Lines } from "../components/Lines";
import RecruiterButton from "../components/RecruiterButton";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>home_</title>
        <meta
          name="description"
          content="Vinay is a self-taught designer and developer with experience in UX design, known for working in early stage startups. Over 2 years, he's worked on many projects for early startups, clients and agencies."
        />
        <link rel="canonical" href="https://uxkeo.com/" />
        {/* Add Open Graph tags */}
        <meta property="og:title" content="UXKEO_ | Vinay Choudhari" />
        <meta
          property="og:description"
          content="Vinay is a self-taught designer and developer with experience in UX design, known for working in early stage startups. Over 2 years, he's worked on many projects for early startups, clients and agencies."
        />
        <meta property="og:url" content="https://uxkeo.com/" />
        <meta property="og:type" content="website" />
        {/* Add Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="UXKEO_ | Vinay Choudhari " />
        <meta
          name="twitter:description"
          content="Vinay is a self-taught designer and developer with experience in UX design, known for working in early stage startups. Over 2 years, he's worked on many projects for early startups, clients and agencies."
        />
      </Helmet>

      <div id="home" className="home__section section">
        <Header />
        <div className="home__container">
          <div className="name">
            <p className="home__title">Vinay</p>
            <p className="home__title">Choudhari</p>
          </div>
          <h2 className="home__subtitle">a self-taught designeer_</h2>
        </div>
        <RecruiterButton />
        <HeaderFooter />
        <Lines HLine={1} VLine={1} />
        <Grids />
      </div>
      <FeaturedWork />
      <Service />
      <Socials />
      <Footer />
    </>
  );
};

export default Home;
