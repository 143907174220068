import React from "react";
import { importImages } from "../utils/importImages";

const images = importImages(
  require.context("../assets/images/gallery", false, /\.(png|jpe?g|JPG|jpeg)$/)
);

const HeaderGallery = () => {
  return (
    <div className="image__gallery">
      <div className="scroller">
        {Object.values(images).map((src, index) => (
          <img key={index} src={src} alt="gallery" />
        ))}

        {Object.values(images).map((src, index) => (
          <img key={index} src={src} alt="gallery" />
        ))}
      </div>
    </div>
  );
};

export default HeaderGallery;
