import React from "react";
import { Link } from "react-router-dom";
import Grids from "../components/Grid";
import { Lines } from "../components/Lines";

const NotFound = () => {
  return (
    <>
      <style>{`
        .not-found {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          background-color: $primary;
          color: $black;
          padding:3rem;
        }
        .not-found h1 {
        position:"relative";
        z-index:10;
          font-size: 12rem;
          margin-bottom: 20px;
          letter-spacing:-8%;
        }
        .not-found p {
                position:"relative";
        z-index:10;
          font-size: 1.5rem;
          margin-bottom: 10px;
          color:#e85a58;
          font-weigth:480;
        }
        .not-found a {
                position:"relative";
        z-index:10;
          margin-top: 20px;
          padding: 10px 20px;
          background-color: #e85a58;
          color: #fff;
          text-decoration: none;
          border-radius: 4px;
          transition: background-color 0.3s;
        }
        .not-found a:hover {
          background-color: $secondary;
        }

          .lines-container {
    .horizontal-container {
      .horizontal-line {
        &:nth-child(1) {
          top: 240px;
          @include media("<=desktop") {
            top: 110px;
          }
        }
      }
    }
    .vertical-container {
      .vertical-line {
        &:nth-child(1) {
          left: 220px;
          @include media("<=desktop") {
            right: 110px;
          }
        }
      }
    }
  }
      `}</style>
      <div className="not-found section">
        <h1>"oops_</h1>
        <p>404! looks like you took a wrong turn in the maze?</p>
        <p>time to head back home_</p>
        <Link to="/">take me home 🥺</Link>
        <Lines HLine={1} VLine={1} />
        <Grids />
      </div>
    </>
  );
};

export default NotFound;
